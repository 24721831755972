import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import 'moment/locale/pt-br';


import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Header, Segment, Loader, Image, Container, Grid } from 'semantic-ui-react'

import If from '../../utils/If';

import { pedidosListRastreioAction } from '../../actions/PedidosActions';
import StepperComponent from './StepperComponent';
import { ContainerHeader } from './styles';
import "./Stepper.css";
import Mapa from './Mapa';
import { BrowserView, MobileView } from 'react-device-detect';
import ModalDetalhes from './ModalDetalhes';
import socketIOClient from "socket.io-client";
import CardEntrega from './CardEntrega';
import { SkeletonNomeHeader, SkeletonPedidoHeader, SkeletonPedidoHeaderMobile, SkeletonStatus, SkeletonStepper, SkeletonStepperMobile } from './Skeletons';

function Rastreamento(props) {

    const { dados, isloading_rastreio } = props;

    const [markerPosition1, setMarkerPosition1] = useState(null);
    const [tempoAtualização, setTempoAtualização] = useState();
    const [distanciaEstimada, setDistanciaEstimada] = useState();
    const [pedidoEntregue, setPedidoEntregue] = useState(null);
    const [openModal, setOpenModal] = useState();
    const intervalRef = useRef(null);

    //alterar para dev para rodar localmente
    const socket = socketIOClient(process.env.REACT_APP_API);


    // const handleMoveMarker = () => {
    //     // Simula um cálculo para alterar a posição do marcador
    //     const newLat = markerPosition1.lat + 0.001; // Aumenta a latitude
    //     const newLng = markerPosition1.lng + 0.001; // Aumenta a longitude
    //     setMarkerPosition1({ lat: newLat, lng: newLng });
    // };

    // console.log(dados.DataSource4)

    useEffect(() => {
        props.pedidosListRastreioAction(props.origem, props.codigo);

        if (isloading_rastreio === false && dados.DataSource1?.[0].dtentrega === null) {
            // setMarkerPosition1({ lat: Number.parseFloat(dados.DataSource1?.[0].from_lat), lng: Number.parseFloat(dados.DataSource1?.[0].from_lng) })


            getLocationBySocket();

        } else if (isloading_rastreio === false && dados.DataSource1?.[0].dtentrega !== null) {
            setMarkerPosition1(null)
        }

        if (dados.DataSource1?.[0].dtinicentr !== null || dados.DataSource1?.[0].dtocorrencia !== null) {
            socket.on("SocketGetLocation", (data) => {
                if (data === null) {
                    setMarkerPosition1(null);
                    setPedidoEntregue(true);
                    props.pedidosListRastreioAction(props.origem, props.codigo);
                    // testereload();
                } else {

                    setMarkerPosition1({ lat: Number.parseFloat(data.lat), lng: Number.parseFloat(data.lng) });

                    const time = moment(data.datelastposition).locale('pt-br').from(moment().locale('pt-br'));
                    setTempoAtualização(time);

                    console.log('MARKER POSITION:', Number.parseFloat(data.lat), Number.parseFloat(data.lng))
                    console.log('MARKER TO:', Number.parseFloat(data.to_lat), Number.parseFloat(data.to_lng))

                    const distancia_estimada = (data.total_distance - data.cur_distance) / 1000;
                    setDistanciaEstimada(distancia_estimada < 0 ? 0 : distancia_estimada.toFixed(2));

                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }

                    // Inicia um novo intervalo para atualizar o tempo a cada segundo
                    intervalRef.current = setInterval(() => {
                        const time = moment(data.datelastposition).locale('pt-br').from(moment());
                        setTempoAtualização(time);
                    }, 1000);
                }
            });
        }

        async function getLocationBySocket() {

            const nunota = dados.DataSource1?.[0].nunota

            // await getLocation(nunota)

            socket.emit('SocketLocationConnect', { nunota });


        }
        //eslint-disable-next-line
    }, [isloading_rastreio]);

    const handleAbrirModal = () => {
        setOpenModal(true)
    }
    const handleFecharModal = () => {
        setOpenModal(false);
    }

    return (
        <>
            <BrowserView>
                <ModalDetalhes open={openModal} close={handleFecharModal} assinatura={dados.DataSource1?.[0].assinaturaclob !== undefined ? dados.DataSource1?.[0].assinaturaclob : null} foto={dados.DataSource2 ? dados.DataSource2 : null} />
                <ContainerHeader>
                    <Container>
                        <Grid verticalAlign='middle'>
                            <Grid.Column width={4}>
                                {props.origem === 'voceconstroi' ? (
                                    <Image src='../img/vcc.png' />
                                ) : (
                                    <Image src='../img/logo.png' width={200} height={80} />
                                )}
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <br />
                                <Header as='h2' textAlign='center'>
                                    {isloading_rastreio ? (


                                        <SkeletonNomeHeader />
                                    ) : (
                                        <>
                                            <Header.Content>
                                                {dados.DataSource1?.[0].nomeparc}
                                            </Header.Content>
                                            <Header.Subheader>
                                                CPF/CNPJ: {dados.DataSource1?.[0].cpf}
                                            </Header.Subheader>
                                        </>
                                    )}
                                </Header>
                            </Grid.Column>

                            <Grid.Column width={4}>
                                <br />
                                <Header as='h3' textAlign='center'>
                                    {isloading_rastreio ? (
                                        <SkeletonPedidoHeader />
                                    ) : (

                                        <Header.Content>
                                            Pedido: {dados.DataSource1?.[0].pedidofbits}
                                            <Header.Subheader>
                                                NF: {dados.DataSource1?.[0].numnota ? dados.DataSource1?.[0].numnota : '-'}
                                            </Header.Subheader>
                                            <Header.Subheader>
                                                OC: {dados.DataSource4?.[1] ? dados.DataSource4?.[1].ORDEMCARGA : '-'}
                                            </Header.Subheader>
                                        </Header.Content>
                                    )}
                                </Header>
                            </Grid.Column>

                        </Grid>
                    </Container>
                </ContainerHeader>

                <Container style={{ marginBottom: '100px' }} >
                    <If test={isloading_rastreio}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', }}>
                            <Segment>
                                <h3>Informações do Rastreio:</h3>
                                <SkeletonStepper />
                                <SkeletonStatus />
                            </Segment>
                        </div>
                    </If>




                    <If test={!isloading_rastreio}>
                        <Segment>

                            <h3>Informações do Rastreio:</h3>

                            <StepperComponent orientation="horizontal" dadosSteps={dados.DataSource1?.[0]} dados={dados.DataSource4} pedidoEntregue={pedidoEntregue} />

                            {dados.DataSource4?.length > 0 && (
                                <div style={{ padding: '20px 70px' }}>
                                    <div className="stepper-container">
                                        <div className={dados.DataSource4[dados.DataSource4.length - 1]?.TIPO.includes("FINALIZADA")
                                            ? "arrow-entregue"
                                            : "arrow"}></div>
                                        {dados.DataSource4[dados.DataSource4.length - 1]?.TIPO.includes("FINALIZADA") ? (
                                            <>
                                                {//eslint-disable-next-line
                                                    (dados.DataSource1?.[0].dtentrega !== null || pedidoEntregue && dados.DataSource1?.[0].dtocorrencia === null) ? (
                                                        <CardEntrega handleAbrirModal={handleAbrirModal} dados={dados.DataSource1?.[0]} />
                                                    ) : (
                                                        <div style={{ display: 'flex', flex: 1, width: '100%', marginTop: 50 }}>

                                                            <Loader active inline='centered'>Carregando informações...</Loader>

                                                        </div>
                                                    )}

                                                {dados.DataSource1?.[0].dtocorrencia && (
                                                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                                        <p>Tivemos uma ocorrência com a sua entrega </p>
                                                    </div>
                                                )}
                                                {//eslint-disable-next-line
                                                    (dados.DataSource1?.[0].dtentrega !== null && dados.DataSource1?.[0].to_lat && dados.DataSource1?.[0].to_lng || pedidoEntregue && dados.DataSource1?.[0].dtocorrencia === null) && (

                                                        <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', marginTop: '30px' }}>

                                                            <Mapa marker={markerPosition1} dadosMapa={dados.DataSource1?.[0]} />

                                                        </div>
                                                    )}

                                            </>
                                        ) : (
                                            <>
                                                {dados.DataSource1?.[0].dtinicentr !== null && dados.DataSource1?.[0].dtentrega === null && dados.DataSource1?.[0].dtocorrencia === null && pedidoEntregue !== true && (

                                                    !markerPosition1 ? (
                                                        <div style={{ display: 'flex', flex: 1, width: '100%', marginTop: 50 }}>

                                                            <Loader active inline='centered'>Carregando mapa...</Loader>

                                                        </div>
                                                    ) :

                                                        (
                                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', alignItems: 'center', marginTop: '30px' }}>

                                                                <div><b>*Esta é uma rota prevista para a sua entrega, porém ela pode sofrer alterações de acordo com o motorista</b></div>
                                                                <div>
                                                                    <b style={{ color: '#8c8c8c', marginRight: '10px' }}>Última atualização {tempoAtualização}.</b>
                                                                    <b style={{ color: '#8c8c8c' }}>Faltam: {distanciaEstimada}km até o seu endereço. </b>
                                                                </div>

                                                                <Mapa marker={markerPosition1} dadosMapa={dados.DataSource1?.[0]} />

                                                            </div>
                                                        )

                                                )}

                                                <div style={{ marginTop: '50px' }}>


                                                    {dados.DataSource4.map((item, index) => (
                                                        <div key={index} className="step">
                                                            <div
                                                                className={
                                                                    item.TIPO === "A CAMINHO" || item.TIPO === 'ENTREGANDO' || item.TIPO === "PRIMEIRA A CAMINHO"
                                                                        ? "step-circle-current"
                                                                        : item.TIPO === "A REALIZAR"
                                                                            ? "step-circle-wait"
                                                                            : "step-circle"
                                                                }
                                                            >
                                                                {index}
                                                            </div>
                                                            <div
                                                                className={`step-line ${item.TIPO === "A CAMINHO" || item.TIPO === "A REALIZAR" || item.TIPO === "ENTREGANDO" || item.TIPO === "PRIMEIRA A CAMINHO"
                                                                    ? "step-line-gray"
                                                                    : ""
                                                                    }`}
                                                                style={{ animationDelay: `${index * 0.5}s` }} // Adiciona atraso progressivo
                                                            ></div>
                                                            <div className="step-description" dangerouslySetInnerHTML={{ __html: item.DESCRICAO }}></div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}

                        </Segment>

                    </If>

                </Container >
            </BrowserView>

            {/* ------------------------------------ SEPARAÇÃO BROWSER / MOBILE --------------------------------------------- */}

            <MobileView>
                <ModalDetalhes open={openModal} close={handleFecharModal} assinatura={dados.DataSource1?.[0].assinaturaclob !== undefined ? dados.DataSource1?.[0].assinaturaclob : null} foto={dados.DataSource2 ? dados.DataSource2 : null} mobile />
                <ContainerHeader>
                    <Container>
                        <Grid verticalAlign='middle'>
                            <Grid.Row centered width={4}>
                                <Grid.Column width={7}>
                                    <div style={{ marginLeft: 20 }}>
                                        {props.origem === 'voceconstroi' ? (
                                            <Image src='../img/vcc.png' />
                                        ) : (
                                            <Image src='../img/logo.png' width={150} height={57} />
                                        )}
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={7} floated='right'>
                                    <Header as='h3' textAlign='center'>
                                        {isloading_rastreio ? (
                                            <SkeletonPedidoHeaderMobile />
                                        ) : (

                                            <Header.Content>
                                                Pedido: {dados.DataSource1?.[0].pedidofbits}
                                                <Header.Subheader>
                                                    NF: {dados.DataSource1?.[0].numnota ? dados.DataSource1?.[0].numnota : '-'}
                                                </Header.Subheader>
                                                <Header.Subheader>
                                                    OC: {dados.DataSource4?.[1] ? dados.DataSource4?.[1].ORDEMCARGA : '-'}
                                                </Header.Subheader>
                                            </Header.Content>
                                        )}
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered width={8}>
                                <Header as='h3' textAlign='center'>
                                    {isloading_rastreio ? (


                                        <SkeletonNomeHeader />
                                    ) : (
                                        <div style={{ padding: '10px 20px' }}>
                                            <Header.Content>
                                                {dados.DataSource1?.[0].nomeparc}
                                            </Header.Content>
                                            <Header.Subheader>
                                                CPF/CNPJ: <br />{dados.DataSource1?.[0].cpf}
                                            </Header.Subheader>
                                        </div>
                                    )}

                                </Header>
                            </Grid.Row>

                        </Grid>
                    </Container>
                </ContainerHeader>

                <Container>

                    <If test={isloading_rastreio}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
                            <Segment>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h3>Informações do Rastreio:</h3>
                                </div>
                                <SkeletonStepperMobile />
                            </Segment>
                        </div>
                    </If>




                    <If test={!isloading_rastreio}>
                        <Segment>

                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h3>Informações do Rastreio</h3>
                            </div>
                            <StepperComponent dadosSteps={dados.DataSource1?.[0]} dados={dados.DataSource4} pedidoEntregue={pedidoEntregue} />

                            {(dados.DataSource1?.[0].dtentrega !== null || pedidoEntregue) && (
                                <CardEntrega mobile dados={dados.DataSource1?.[0]} handleAbrirModal={handleAbrirModal} />
                            )}


                            {dados.DataSource1?.[0].dtinicentr !== null && dados.DataSource1?.[0].dtentrega === null && pedidoEntregue !== true && (

                                !markerPosition1 ? (
                                    <div style={{ display: 'flex', flex: 1, width: '100%', marginTop: 50 }}>

                                        <Loader active inline='centered'>Carregando mapa...</Loader>

                                    </div>
                                ) : (

                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', alignItems: 'center', marginTop: '30px' }}>

                                        <div style={{ textAlign: 'center', marginBottom: 30 }}><b>*Esta é uma rota prevista para a sua entrega, porém ela pode sofrer alterações de acordo com o motorista</b></div>
                                        <div>
                                            <b style={{ color: '#8c8c8c', textAlign: 'center', marginBottom: 20 }}>Última atualização {tempoAtualização}.</b>
                                        </div>

                                        <b style={{ color: '#8c8c8c', marginBottom: 30, textAlign: 'center' }}>Faltam: {distanciaEstimada}km até o seu endereço.</b>

                                        {markerPosition1 && <Mapa marker={markerPosition1} dadosMapa={dados.DataSource1?.[0]} />}

                                    </div>

                                )
                            )}

                            {dados.DataSource1?.[0].dtocorrencia && (
                                <div>
                                    <p>Tivemos uma ocorrência com a sua entrega </p>
                                </div>
                            )}

                            {//eslint-disable-next-line
                                (dados.DataSource1?.[0].dtentrega !== null && dados.DataSource1?.[0].to_lat && dados.DataSource1?.[0].to_lng || pedidoEntregue) && (
                                    <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', marginTop: '30px' }}>

                                        <Mapa marker={markerPosition1} dadosMapa={dados.DataSource1?.[0]} />

                                    </div>
                                )}

                        </Segment>

                    </If>

                </Container >
            </MobileView>
        </>
    );
}

Rastreamento.defaultProps = {
    origem: ''
    , codigo: ''
}
Rastreamento.propTypes = {
    origem: PropTypes.string.isRequired
    , codigo: PropTypes.string.isRequired

}

const mapStateToProps = state => ({
    dados: state.PedidosReducer.dados_rastreio
    , isloading_rastreio: state.PedidosReducer.isloading_rastreio
})

export default withRouter(connect(mapStateToProps, {
    // pedidosListAction,
    pedidosListRastreioAction
})(Rastreamento));